import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle
} from "@mui/material";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import {useEffect, useState} from "react";
import validator from "validator";

import {NewUser} from "../../store/apis/usersApi";

const {isEmail} = validator;

export const AddUserForm = ({
                              open,
                              handleAddClose,
                              handleAddUser
                            }: {
  open: boolean;
  handleAddClose: () => void;
  handleAddUser: (newUser: NewUser) => void;
}) => {
  const initErrors = {
    name: "",
    email: "",
    password: ""
  };

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errors, setErrors] = useState<NewUser>(initErrors);

  const close = () => {
    setErrors(initErrors);
    handleAddClose();
  };
  const addUser = () => {
    const err: NewUser = {name: "", email: "", password: ""};
    let errors = false;
    if (!isEmail(email)) {
      errors = true;
      err.email = "Tarkasta sähköpostiosoite";
    }
    if (name.length < 1) {
      errors = true;
      err.name = "Anna nimi";
    }
    if (password.length < 5) {
      errors = true;
      err.password = "Anna salasanan tulee olla vähintää 5 merkkiä";
    }
    if (!errors) {
      handleAddUser({email: email.toLowerCase(), name, password});
      close();
    } else {
      setErrors(err);
    }
  };

  useEffect(() => {
    if (!open) {
      setName("");
      setPassword("");
      setEmail("");
    }
  }, [open]);

  return (
    <Dialog open={open} onClose={close}>
      <DialogTitle>Lisää käyttäjä</DialogTitle>
      <DialogContent>
        <TextField
          error={Boolean(errors.name)}
          helperText={errors.name}
          autoFocus
          margin="dense"
          id="name"
          label="Nimi"
          type="text"
          fullWidth
          variant="standard"
          onChange={event => setName(event.target.value)}
          value={name}
        />
        <TextField
          error={Boolean(errors.email)}
          helperText={errors.email}
          autoFocus
          margin="dense"
          id="email"
          label="Sähköpostiosoite"
          type="email"
          fullWidth
          variant="standard"
          onChange={event => setEmail(event.target.value)}
          value={email}
        />
        <TextField
          error={Boolean(errors.password)}
          helperText={errors.password}
          autoFocus
          margin="dense"
          id="password"
          label="Salasana"
          type="password"
          fullWidth
          variant="standard"
          onChange={event => setPassword(event.target.value)}
          value={password}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={close}>Peru</Button>
        <Button onClick={addUser}>Lisää</Button>
      </DialogActions>
    </Dialog>
  );
};
