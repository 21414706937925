import * as React from "react";
import {useEffect, useState} from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Title from "../dashboard/Title";
import Grid from "@mui/material/Grid";
import {ConnectedSensors} from "./ConnectedSensors";
import Button from "@mui/material/Button";
import {Animal, Customer} from "../../store/apis/customersApi";
import {SelectCustomer} from "./SelectCustomer";
import remoteGATTServerManager, {RawData} from "../utils/RemoteGATTServerManager";
import {RenderGraphs} from "./RenderGraphs";
import {SaveMeasurementResults} from "./SaveMeasurementResults";
import {useAddResultMutation, useLoadAllSensorsQuery} from "../../store";
import RemoteGATTServerManager from "../utils/RemoteGATTServerManager";
import {SuccessErrorToast} from "../utils/SuccessErrorToast";

export default function Measurement() {
  const [customer, setCustomer] = useState<Customer>();
  const [animal, setAnimal] = useState<Animal>();
  const [open, setOpen] = useState(false);
  const [saveOpen, setSaveOpen] = useState(false);
  const [measurementStarted, setMeasurementStarted] = useState(false);
  const [downloading, setDownloading] = useState(false);
  const [rawData, setRawData] = useState<RawData[]>();

  const [movement, setMovement] = useState("Puhdas");
  const [leg, setLeg] = useState("");

  const sensors = useLoadAllSensorsQuery(null);
  const [addMeasurementResult, addMeasurementResultResult] = useAddResultMutation();
  const [successOpen, setSuccessOpen] = useState(false);
  const [errorOpen, setErrorOpen] = useState(false);

//  let downloadInterval: any;
  useEffect(() => {
    RemoteGATTServerManager.downloadReadyCallback = setRawData;
  }, []);

  useEffect(() => {
    if (addMeasurementResultResult.isSuccess) {
      setSuccessOpen(true);
    }
    if (addMeasurementResultResult.isError) {
      setErrorOpen(true);
    }
  }, [addMeasurementResultResult]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClickSaveOpen = () => {
    setSaveOpen(true);
  };

  const handleClose = (
    event: React.SyntheticEvent<unknown>,
    reason?: string
  ) => {
    if (reason !== "backdropClick") {
      setOpen(false);
    }
  };

  const handleCustomerChange = (customer: Customer) => {
    setCustomer(customer);
    setAnimal(undefined);
  };
  const handleCancel = () => {
    setCustomer(undefined);
    setAnimal(undefined);
    setOpen(false);
  };

  const startMeasurement = async () => {
    const servers = remoteGATTServerManager.remoteGATTServers;
    if (servers.length) {
      for (let i = 0; i < servers.length; i++) {
        await remoteGATTServerManager.startMeasurement(servers[i]);
        await remoteGATTServerManager.readSensorState(servers[i]);
      }
    }
    setMeasurementStarted(true);
    setRawData([]);
    remoteGATTServerManager.downloadData = [];
  };

  const stopMeasurement = async () => {
    const servers = remoteGATTServerManager.remoteGATTServers;
    if (servers.length) {
      for (let i = 0; i < servers.length; i++) {
        await remoteGATTServerManager.stopMeasurement(servers[i]);
        await remoteGATTServerManager.stopNotifications(servers[i]);
        await remoteGATTServerManager.readSensorState(servers[i]);
      }
      setMeasurementStarted(false);
    }
  }

  const downloadData = async () => {
    remoteGATTServerManager.downloadData = [];
    setDownloading(true);
    const servers = remoteGATTServerManager.remoteGATTServers;
    if (servers.length) {
      let i = 0;
      let int = setInterval(async () => {
        if (i < servers.length) {
          if (remoteGATTServerManager.downloading === "") {
            await remoteGATTServerManager.downloadSingle(servers[i].armId);
            i++;
          }
        } else {
          clearInterval(int);
          setDownloading(false);
        }
      }, 500)
    }
  };

  const singleDownloadCb = () => {
    setRawData(remoteGATTServerManager.downloadData);
  }

  const renderStartStopButton = () => {
    if (measurementStarted) {
      return (
        <Button onClick={stopMeasurement} variant="contained">
          Lopeta mittaus
        </Button>
      );
    } else if (animal) {
      return (
        <Button onClick={startMeasurement} variant="contained">
          Aloita mittaus
        </Button>
      );
    } else
      return (
        <Button variant="contained" onClick={handleClickOpen}>
          Valitse asiakas
        </Button>
      );
  };

  const renderDownload = () => {
    if (!measurementStarted) {
      return (
        <Button onClick={downloadData} variant="contained">
          Lataa kaikki mittaustulokset
        </Button>
      );
    } else return <div/>;
  };

  const renderSaveResults = () => {
    if (!measurementStarted && rawData?.length) {
      return (
        <Button onClick={handleClickSaveOpen} variant="contained">
          Tallenna mittaustulokset
        </Button>
      );
    } else return <div/>;
  };

  const renderChangeCustomer = () => {
    if (!measurementStarted && customer && animal) {
      return (
        <>
          {customer.name} / {animal.name}
          <Button onClick={handleClickOpen}>Vaihda asiakas</Button>
        </>
      );
    }
  };

  const handleSaveClose = () => {
    setSaveOpen(false);
    setMovement("Puhdas");
    setLeg("");
  }
  const handleSave = () => {
    const customerId = customer?.id;
    const animalId = animal?.id;
    if (customerId && animalId && rawData) {
      let dataString = (JSON.stringify(rawData));
      console.log(dataString);
      if (sensors.data) {
        sensors.data.forEach(sensor => {
          if (sensor.sensorId && sensor.humanName) {
            dataString = dataString.replaceAll(sensor.sensorId, sensor.position.toString())
            console.log(dataString);
          }
        })
        addMeasurementResult({
          data: dataString, movement, leg, customerId, animalId
        });
        handleSaveClose();
      }
    }
  }

  return (
    <React.Fragment>
      <SuccessErrorToast
        successOpen={successOpen}
        errorOpen={errorOpen}
        successMessage="Mittaustulos tallennettu onnistuneesti!"
        errormessage="Mittaustuloksen tallentaminen epäonnistui!"
        setSuccessOpen={setSuccessOpen}
        setErrorOpen={setErrorOpen}
      />
      <SaveMeasurementResults
        movement={movement}
        leg={leg}
        setMovement={setMovement}
        setLeg={setLeg}
        open={saveOpen}
        close={handleSaveClose}
        save={handleSave}
      />
      <SelectCustomer
        open={open}
        handleClose={handleClose}
        setCustomer={handleCustomerChange}
        customer={customer}
        handleCancel={handleCancel}
        animal={animal}
        setAnimal={setAnimal}
      />
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Title>Mittaus</Title>
          <ConnectedSensors downloading={downloading} singleDownloadCb={singleDownloadCb}
          />
          <Table size="small">
            <TableHead>
              <TableRow></TableRow>
            </TableHead>
            <TableBody></TableBody>
          </Table>
        </Grid>
        <Grid item xs={12}>
          <RenderGraphs data={rawData}/>
        </Grid>

        <Grid item xs={3}>
          {renderStartStopButton()}
        </Grid>
        <Grid item xs={3}>
          {renderChangeCustomer()}
        </Grid>

        <Grid item xs={3}>
          {renderDownload()}
        </Grid>
        <Grid item xs={3}>
          {renderSaveResults()}
        </Grid>
      </Grid>
    </React.Fragment>
  );
}
