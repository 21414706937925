import {createApi, fetchBaseQuery} from "@reduxjs/toolkit/query/react";
import {RootState} from "../index";
import {API_BASE_URL} from "../../constants";


export interface Customer {
  id?: number;
  name: string;
  animals?: Animal[];
}

export interface Animal {
  id?: number;
  name: string;
  species: string;
  race?: string;
  customer: number;
  DoB: string;
}

const customersApi = createApi({
  reducerPath: "customers",
  baseQuery: fetchBaseQuery({
    baseUrl: `${API_BASE_URL}`,
    prepareHeaders: (headers, {getState}) => {
      const token = (getState() as RootState).auth.token;
      if (token) {
        headers.set("authorization", `Bearer ${token}`);
      }
    }
  }),
  tagTypes: ["loadCustomers"],
  endpoints(builder) {
    return {
      loadAllCustomers: builder.query<Customer[], null>({
        providesTags: users => ["loadCustomers"],
        query: () => {
          return {
            url: "/customers",
            method: "GET"
          };
        }
      }),
      deleteCustomer: builder.mutation<Customer, Customer>({
        invalidatesTags: ["loadCustomers"],
        query: user => {
          return {
            url: `/customers/${user.id}`,
            method: "DELETE"
          };
        }
      }),
      addCustomer: builder.mutation<Customer, Customer>({
        invalidatesTags: ["loadCustomers"],
        query: newUser => {
          return {
            url: "/customers",
            method: "POST",
            body: {
              name: newUser.name
            }
          };
        }
      }),
      modifyCustomer: builder.mutation<Customer, Customer>({
        invalidatesTags: ["loadCustomers"],
        query: user => {
          return {
            url: `/customers/${user.id}`,
            method: "PATCH",
            body: {
              name: user.name
            }
          };
        }
      }),
      addAnimal: builder.mutation<Animal, Animal>({
        invalidatesTags: ["loadCustomers"],
        query: animal => {
          return {
            url: "/animals",
            method: "POST",
            body: {
              name: animal.name,
              species: animal.species,
              race: animal.race,
              customer: animal.customer,
              DoB: animal.DoB
            }
          };
        }
      }),
      modifyAnimal: builder.mutation<Animal, Animal>({
        invalidatesTags: ["loadCustomers"],
        query: animal => {
          return {
            url: `/animals/${animal.id}`,
            method: "PATCH",
            body: {
              ...animal
            }
          };
        }
      }),
      deleteAnimal: builder.mutation<Animal, Animal>({
        invalidatesTags: ["loadCustomers"],
        query: animal => {
          return {
            url: `/animals/${animal.id}`,
            method: "DELETE"
          };
        }
      })
    };
  }
});

export const {
  useLoadAllCustomersQuery,
  useDeleteCustomerMutation,
  useAddCustomerMutation,
  useAddAnimalMutation,
  useDeleteAnimalMutation,
  useModifyCustomerMutation,
  useModifyAnimalMutation
} = customersApi;
export {customersApi};
